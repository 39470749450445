import { Grid, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink, useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from 'routes';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';


const NavTabs = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',

  '& .MuiTab-root': {
    color: theme.palette.common.white,

    '&:hover': {
      borderBottomColor: theme.palette.common.white,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const VerticalNavTabs = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',

  '& .MuiTabs-root': {
    boxShadow: 'none',
  },
  '& .MuiTabs-vertical .MuiTab-root': {
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
  },
}));

const WrapperTabs = ({ vertical = false, children }) =>
  vertical ? (
    <VerticalNavTabs container>{children}</VerticalNavTabs>
  ) : (
    <NavTabs container>{children}</NavTabs>
  );

WrapperTabs.propTypes = {
  vertical: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

function NavigationMenu({ column: vertical }) {
  const location = useLocation();

  const pathname = location.pathname.split('/')[1] || false;
  const { oohAccess, inMarketAccess } = useSelector(state => state.user);


  return (
      <WrapperTabs vertical={vertical}>
        <Tabs
          value={pathname}
          textColor={vertical ? 'primary' : 'inherit'}
          orientation={vertical ? 'vertical' : 'horizontal'}
        >
          {oohAccess && (
            <Tab
              label='OOH'
              value='ooh'
              component={NavLink}
              to={ROUTE_PATHS.OOH}
            />
          )}
          {inMarketAccess && (
            <Tab
              label='In Market Audiance'
              value='in-market-audiance'
              component={NavLink}
              to={ROUTE_PATHS.IN_MARKET_AUDIANCE}
            />
          )}         
          <Tab
            label='Campaign Visualiser'
            value='campaign-visualiser'
            component={NavLink}
            to={ROUTE_PATHS.CAMPAIGN_VISUALISER}
          />
        </Tabs>
      </WrapperTabs>
  );
}

NavigationMenu.propTypes = {
  column: PropTypes.bool,
};

export default NavigationMenu;
