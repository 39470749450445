import Header from 'components/common/header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setLoadingApp } from 'store/appSlice'
import PropTypes from 'prop-types';

function DefaultView({ children }) {
  const dispatch = useDispatch();
  const { viewport } = useSelector((state) => state.carto);

  useEffect(() => {
    if(viewport)
      dispatch(setLoadingApp(false));
  }, [viewport])

  useEffect(() => {
    dispatch(setLoadingApp(true));
  }, [])

  return (
    <>
      <Header />
      {children}
    </>
  );
}

DefaultView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultView;
