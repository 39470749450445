import axios from 'axios';
import LZString from 'lz-string';

const showErrorToUser = (message) => {
  alert(message); // Replace with a notification library if desired
};

const logError = (err) => {
  console.error("Error fetching data: ", err);
};

export const handlePaginatedPostRequest = async (endPoint, body, onPageFetched) => {
  const url = process.env.REACT_APP_BASE_URL + '/' + endPoint;
  let allData = [];
  let page = 1;
  const limit = 1000000; 

  try {
    while (true) {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          'x-platform': process.env.REACT_APP_SECRET_PLATFORM,
          'x-token': process.env.REACT_APP_SECRET_TOKEN
        },
        body: JSON.stringify({ ...body, page, limit })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      allData = allData.concat(responseData);

      if(onPageFetched){
        onPageFetched(responseData)
      }

      if (responseData.length < limit) {
        break;
      }

      page += 1;
    }

    return { data: allData };

  } catch (err) {
    logError(err);
    showErrorToUser('An error occurred while fetching data. Please try again later. Our technical team has been notified and is working on it.');
    return { data: [] };
  }
};

export const handlePostRequest = async (endPoint, body) => {
  const url = process.env.REACT_APP_BASE_URL + '/' + endPoint;
  
  try {
    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": 'application/json',
        'x-platform': process.env.REACT_APP_SECRET_PLATFORM,
        'x-token' : process.env.REACT_APP_SECRET_TOKEN
      }
    });

    if (typeof response.data === 'string') {
      const parsedData = JSON.parse(response.data);
      response.data = parsedData;
    }
    
    return response;
  } catch (err) {
    logError(err);
    
    if (err.response) {
      const { status } = err.response;
      if (status === 401) {
        showErrorToUser('Invalid username or password');
      } else if (status === 500) {
        showErrorToUser('Internal Server Error. Please try again later. Our technical team has been notified and is working on it.');
      } else {
        showErrorToUser(`An error occurred: ${status}. Our technical team has been notified and is working on it.`);
      }
    } else {
      showErrorToUser('An unexpected error occurred. Please try again later. Our technical team has been notified and is working on it.');
    }
    return { data: [] };
  }
};

export const handleGetRequests = async (endPoint, id = '') => {
  const url = `${process.env.REACT_APP_BASE_URL}/${endPoint}${id ? `/${id}` : ''}`;
  
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": 'application/json',
        'x-platform': process.env.REACT_APP_SECRET_PLATFORM,
        'x-token' : process.env.REACT_APP_SECRET_TOKEN
      }
    });

    if (!response.headers['x-content-compressed'] && typeof response.data === 'string') {
      const parsedData = JSON.parse(response.data);
      response.data = parsedData;
    } else if (response.headers['x-content-compressed']) {
      const decompressed = JSON.parse(LZString.decompress(response.data));
      response.data = decompressed;
    }

    return response;
  } catch (err) {
    logError(err);
    showErrorToUser('An error occurred while fetching data. Please try again later. Our technical team has been notified and is working on it.');
    return { data: [] };
  }
};