import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import BrandLocationIcon from 'assets/img/download.png';
import FillingCircularProgress from './child/FillingCircularProgress';
import LoadingPercentageText from './child/LoadingPercentageText';
import PropTypes from 'prop-types';

const RotatingIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

function CircularProgressWithIcon(props) {

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }} >
        {props.isFillingLoader && !props.loaderHidden ? <FillingCircularProgress size={props.size || 50} {...props}/> : !props.isFillingLoader && !props.loaderHidden ? <CircularProgress className='loader' size={props.size || 50}/> : null}
        
        {!props.iconHidden && (
          <RotatingIcon className='rotating-icon'>
            <img src={BrandLocationIcon} alt="Loading Icon" style={props.iconStyles ? props.iconStyles : { width: '24px', height: '24px' }} />
          </RotatingIcon>
        )}
      </Box>
      {props.showLoadingText && (<LoadingPercentageText />)}
    </div>
  );
}

CircularProgressWithIcon.propTypes = {
  isFillingLoader: PropTypes.bool,
  loaderHidden: PropTypes.bool,
  size: PropTypes.number,
  iconHidden: PropTypes.bool,
  iconStyles: PropTypes.object,
  showLoadingText: PropTypes.bool,
};

export default CircularProgressWithIcon;